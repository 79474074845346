@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

:root {
  --card-surface: #f5f5f5;
  --black: #000;
  --white: #fff;
}

$font-family-base: 'Inter', sans-serif;

$btn-font-weight: 600;

$btn-font-size-sm: 12px;
$btn-font-size: 14px;
$btn-font-size-lg: 16px;

$btn-padding-x-sm: 24px;
$btn-padding-x: 24px;
$btn-padding-x-lg: 24px;

$btn-padding-y-sm: 10px;
$btn-padding-y: 10px;
$btn-padding-y-lg: 14px;

$btn-border-radius-sm: 20px;
$btn-border-radius: 20px;
$btn-border-radius-lg: 24px;

$gray-100: #f8fafc;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

$evland-green-100: #d1ecda;
$evland-green-200: #aaddbb;
$evland-green-300: #6aca82;
$evland-green-400: #61ba76;
$evland-green-500: #50ad5d;
$evland-green-600: #489c54;
$evland-green-700: #418c4b;
$evland-green-800: #418c4b;
$evland-green-900: #418c4b;

$primary: $evland-green-500;
$secondary: $gray-500;

$body-color: $gray-900;
